import { styled } from 'styletron-react';
import { rhythm } from '../../utils/typography';

const PageContainer = styled('div', {
  marginLeft: `auto`,
  marginRight: `auto`,
  maxWidth: rhythm(24),
  padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
});

export { PageContainer };
