import React from 'react';
import { Link } from 'gatsby';
import { RalewayText } from './RalewayText';

export function Logo() {
  return (
    <Link to="/">
      <RalewayText color="#000000">w</RalewayText>
      <RalewayText color="#01a8c6">Sołtysiak </RalewayText>
      <RalewayText color="#000000">Blog</RalewayText>
    </Link>
  )
}
