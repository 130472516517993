import React from 'react';
import { node, bool, string } from 'prop-types';
import { PageContainer } from './PageContainer';
import { LogoContainer } from './Logo/LogoContainer';
import { Logo } from './Logo/Logo';
import { ContentContainer } from './ContentContainer';
import { Footer } from './Footer/Footer';
import { FooterLink } from './Footer/FooterLink';

export function Layout({ children, centerContent, description }) {
  return (
    <PageContainer>
      <header>
        <LogoContainer>
          <Logo />
          { description }
        </LogoContainer>
      </header>
      <ContentContainer $isCentered={centerContent}>{children}</ContentContainer>
      <Footer>
        <FooterLink href="mailto:soltysiak.wojciech@gmail.com" target="_blank">
          Napisz do mnie
        </FooterLink>
      </Footer>
    </PageContainer>
  )
}

Layout.propTypes = {
  children: node.isRequired,
  centerContent: bool,
  description: string.isRequired,
}

Layout.defaultProps = {
  centerContent: false,
}
