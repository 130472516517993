import Typography from "typography";
import altonTheme from "typography-theme-alton";

altonTheme.overrideThemeStyles = () => ({
  "a.gatsby-resp-image-link": {
    boxShadow: "none",
  },
  "a": {
    color: "#01a8c6",
  },
  "a:visited": {
    color: "#a900a9",
  },
  "a:hover, a:active": {
    color: "#000000",
    'text-decoration': 'underline',
  },
})
altonTheme.headerFontFamily = ["IBM Plex Serif", "Georgia", "Times", "serif"];
altonTheme.bodyFontFamily = ["IBM Plex Serif", "Georgia", "Times", "serif"];
delete altonTheme.googleFonts;

const typography = new Typography(altonTheme);

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles();
}

export default typography;
export const rhythm = typography.rhythm;
export const scale = typography.scale;
