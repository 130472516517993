import { withStyle } from 'styletron-react';
import { BorderedContainer } from '../../BorderedContainer';
import { rhythm } from '../../../utils/typography';

export const LogoContainer = withStyle(BorderedContainer, {
  marginBottom: `${rhythm(1)}`,
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
});
