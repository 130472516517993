import { styled } from 'styletron-react';

const RalewayText = styled('span', ({ color }) => ({
  color,
  fontSize: '36px',
  fontWeight: 'bold',
  fontFamily: `'Raleway', sans-serif`,
  '@media screen and (min-width: 600px)': {
    fontSize: '42px',
  }
}));

export { RalewayText };
