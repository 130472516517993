import React from 'react';
import { string, arrayOf, bool } from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

const LANG = 'pl-PL';

const getKeywordsMeta = keywords => [{
  name: `keywords`,
  content: keywords.join(`, `),
}];

const getOpenGraphBlogPostMeta = (publishedTime, author, featuredImage) => [
  {
    name: `og:article:published_time`,
    content: publishedTime,
  },
  {
    name: `og:article:author`,
    content: author,
  },
  {
    property: `og:image`,
    content: featuredImage,
  }
];

const getOpenGraphBasicMeta = (title, canonicalUrl, description) => [
  {
    property: `og:title`,
    content: title,
  },
  {
    property: `og:url`,
    content: canonicalUrl,
  },
  {
    property: `og:description`,
    content: description,
  },
  {
    property: `og:locale`,
    content: LANG,
  },
];

const getTwitterMeta = author => [
  {
    name: `twitter:card`,
    content: `summary`,
  },
  {
    name: `twitter:creator`,
    content: author,
  },
];


export function SeoTags({
  title,
  description,
  keywords,
  slug,
  featuredImage,
  publishedTime,
  isBlogPost
}) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={({
        site: {
          siteMetadata: { brandTitle, author, siteUrl }
        }
      }) => {
        const canonicalUrl = `${siteUrl}${slug}`;
        return (
          <Helmet
            htmlAttributes={{
              lang: LANG,
            }}
            title={title}
            titleTemplate={`%s | ${brandTitle}`}
            link={[
              {
                rel: 'canonical',
                href: canonicalUrl
              },
            ]}
            meta={[
              {
                name: `description`,
                content: description,
              },
              ...(keywords.length > 0
                ? getKeywordsMeta(keywords)
                : []
              ),
              {
                property: `og:type`,
                content: isBlogPost ? 'article' : `website`,
              },
              ...getOpenGraphBasicMeta(title, canonicalUrl, description),
              ...(isBlogPost
                ? getOpenGraphBlogPostMeta(
                    publishedTime,
                    author,
                    `${siteUrl}${featuredImage}`
                  )
                : []
              ),
              ...getTwitterMeta(author),
            ]}
          />
        )
      }}
    />
  )
}

SeoTags.defaultProps = {
  keywords: [],
  slug: '',
  featuredImage: '',
  publishedTime: '',
  isBlogPost: false,
}

SeoTags.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
  keywords: arrayOf(string),
  slug: string,
  featuredImage: string,
  publishedTime: string,
  isBlogPost: bool,
}

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        brandTitle: title
        author
        siteUrl
      }
    }
  }
`
